<script>
    /**
     * name：
     * user：sa0ChunLuyu
     * date：2022-03-10 16:54:43
     */
    import ltree from './tree.vue'

    export default {
        components: {ltree},
        data() {
            return {}
        },
        mounted() {
        },
        methods: {}
    }
</script>
<template>
    <div class="treesidebar">
        <div class="treeside"></div>
        <div class="treetitle">检测项目列表</div>
        <ltree></ltree>
    </div>
</template>
<style scoped>
    .treeside{ position: fixed; width: 230px; height: calc(100vh - 110px); border: 1px solid #eeeeee; left: 0; border-left: none}
    .treetitle{ position: fixed; width: 230px; line-height: 40px; font-size: 14px; font-weight: bold; background: #f9f9f9; left: 0; z-index: 99999; padding-left: 10px }
</style>
