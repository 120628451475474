<script>
    /**
     * name：
     * user：sa0ChunLuyu
     * date：2022-03-10 16:55:12
     */
    import tree_data from './tree_data.js'
    import ltree from '../../../components/sa0/ltree/ltree.vue'

    export default {
        components: {ltree},
        data() {
            return {
                tree_data,
                menu_arr: [],
                all_menu: [],
            }
        },
        mounted() {
            this.menuarrmaker()
        },
        methods: {
            renameDo(item, name) {
                console.log(item, name)
            },
            menuarrmaker() {
                this.all_menu = [{
                    name: '新建文件夹',
                    show: -1,
                    func: () => {
                        console.log('根目录 新建文件夹')
                    }
                }]
                this.menu_arr = [{
                    name: '重命名',
                    show: -1,
                    func: (item) => {
                        this.$refs['ltreeref'].renameActive(item.id)
                        console.log(item, '重命名')
                    }
                }, {
                    name: '新建内容',
                    show: 0,
                    c_show: 1,
                    func: (item) => {
                        console.log(item, '新建内容')
                    }
                }, {
                    name: '新建文件夹',
                    show: 0,
                    c_show: 0,
                    func: (item) => {
                        console.log(item, '新建文件夹')
                    }
                },
                    {
                        name: '创建副本',
                        show: -1,
                        func: (item) => {
                            console.log(item, '创建副本')
                        }
                    }, {
                        name: '上移',
                        show: -1,
                        func: (item) => {
                            console.log(item, '上移')
                        }
                    }, {
                        name: '下移',
                        show: -1,
                        func: (item) => {
                            console.log(item, '下移')
                        }
                    },
                    {
                        name: '删除',
                        show: -1,
                        func: (item) => {
                            console.log(item, '删除')
                        }
                    },]
            },
            treeCallback(click_arr) {
                console.log(click_arr)
            }
        }
    }
</script>
<template>
    <div>
        <div class="tree_wrapper">
            <ltree ref="ltreeref" w="225px" ot="only" :dt="0" :it="1" :data="tree_data" all="all" :all_menu="all_menu" :menu="menu_arr" :callback="treeCallback" :renameDo="renameDo" class="treemain"></ltree>
        </div>
    </div>
</template>
<style scoped>
    .tree_wrapper{
        position: relative;
        height: 800px;
        overflow-y: auto;
        margin-left: -15px;
        }

    .treemain{ margin-top: 50px}
</style>
