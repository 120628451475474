const data_deep = 5
const dir_child_count = 10
const dir_type = 0
const item_type = 1
let id = 1
const itemmaker = () => {
    id++
    return {
        id, type: item_type, name: '内容' + id, content: {pid: 1}
    };
}
const dirmaker = (deep = 0) => {
    id++
    let item = {
        id, type: dir_type, name: '文件夹' + id, content: {pid: 1}
    }
    let has_child = Math.floor(Math.random() * 100)
    item.children = []
    if (has_child > 20 && deep <= data_deep) {
        let child_count = Math.floor(Math.random() * (dir_child_count + 1))
        let child_type = Math.floor(Math.random() * 100)
        for (let i = 0; i < child_count; i++) {
            if (child_type > 60) {
                item.children.push(itemmaker())
            } else {
                item.children.push(dirmaker(deep + 1))
            }
        }
    }
    return item;
}
let list = []
let dir_count = Math.floor(Math.random() * (dir_child_count + 1)) + 1
for (let i = 0; i <= dir_count; i++) {
    id++
    list.push(dirmaker())
}
export default list
